import React, { useState } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { createParagraph, createArticles } from '../../graphql/mutations';
import { generateClient } from 'aws-amplify/api';

export default function Modal({ isOpen, onClose, userId }) {
  const { register, handleSubmit, control, formState: { errors } } = useForm();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'paragraphs',
  });

  const client = generateClient();
  const [loading, setLoading] = useState(false);
  const [submissionError, setSubmissionError] = useState(null);
  const TopTabArray = ["Actualité", "News", "Portrait", "Chronique", "Agenda"];

  if (!isOpen) return null;

  const onSubmitForm = async (data) => {
    setLoading(true);
    setSubmissionError(null);

    if (!userId) {
      setSubmissionError("User ID is required. Please make sure you are logged in.");
      setLoading(false);
      return;
    }

    try {
      const articleData = {
        Titles: data.title,
        images: data.imageLink,
        userID: userId,
        rubrique: data.rubrique,  // Add the selected rubrique to the article data
      };

      const newArticle = await client.graphql({
        query: createArticles,
        variables: { input: articleData },
      });

      const articleId = newArticle.data.createArticles.id;

      for (let i = 0; i < data.paragraphs.length; i++) {
        const paragraphData = {
          text: data.paragraphs[i],
          title: `Paragraph ${i + 1}`,
          order: i + 1,
          articlesID: articleId,
        };

        await client.graphql({
          query: createParagraph,
          variables: { input: paragraphData },
        });
      }

      console.log("Article created successfully");
      onClose();
    } catch (error) {
      console.error('Error creating article:', error);
      setSubmissionError('Failed to create the article. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white rounded-lg w-96 p-6 relative">
        <h2 className="text-2xl font-bold mb-4">Create Article</h2>
        {submissionError && <p className="text-red-500 mb-4">{submissionError}</p>}
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <input
            className="w-full mb-4 p-2 border rounded"
            type="text"
            placeholder="Title"
            {...register('title', { required: 'Title is required' })}
          />
          {errors.title && <p className="text-red-500">{errors.title.message}</p>}

          <select
            className="w-full mb-4 p-2 border rounded"
            {...register('rubrique', { required: 'Rubrique is required' })}
          >
            <option value="">Select Rubrique</option>
            {TopTabArray.map((rubrique, index) => (
              <option key={index} value={rubrique}>
                {rubrique}
              </option>
            ))}
          </select>
          {errors.rubrique && <p className="text-red-500">{errors.rubrique.message}</p>}

          <input
            className="w-full mb-4 p-2 border rounded"
            type="text"
            placeholder="Image Link"
            {...register('imageLink')}
          />

          {fields.map((field, index) => (
            <div key={field.id} className="mb-4">
              <textarea
                className="w-full p-2 border rounded"
                placeholder={`Paragraph ${index + 1}`}
                {...register(`paragraphs.${index}`, { required: 'Paragraph text is required' })}
              ></textarea>
              {errors.paragraphs && errors.paragraphs[index] && (
                <p className="text-red-500">{errors.paragraphs[index].message}</p>
              )}
            </div>
          ))}

          <div className="flex justify-between mb-4">
            <button
              type="button"
              className="bg-green-500 text-white px-4 py-2 rounded"
              onClick={() => append('')}
            >
              Add Paragraph
            </button>
            <button
              type="button"
              className="bg-red-500 text-white px-4 py-2 rounded"
              onClick={() => remove(fields.length - 1)}
              disabled={fields.length === 1}
            >
              Remove Last Paragraph
            </button>
          </div>

          <div className="flex justify-end">
            <button
              type="button"
              className="bg-gray-300 text-black px-4 py-2 rounded mr-2"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded"
              disabled={loading}
            >
              {loading ? 'Submitting...' : 'Submit'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

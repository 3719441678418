import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Card from '../Components/Card';
import Footers from '../Components/Footers';
import Header from '../Components/Header';
import { generateClient } from 'aws-amplify/api';
import { listCovers, listArticles } from '../graphql/queries';

export default function Home() {
  const TopTabArray = ["Actualité", "News", "Portrait", "Chronique", "Agenda"];
  const [articles, setArticles] = useState([]);
  const [covers, setCovers] = useState([]);
  const [search, setSearch] = useState('');

  const client = generateClient();
 
  const pageName = "Actualité";

  const getArticles = async () => {
    try {
      const listArticlesResponse = await client.graphql({
        query: listArticles,
      });
      setArticles(listArticlesResponse.data.listArticles.items);
    } catch (error) {
      console.error("Error fetching articles:", error);
    }
  };

  const getCoverImage = async () => {
    try {
      const listCoversResponse = await client.graphql({
        query: listCovers,
      });
      setCovers(listCoversResponse.data.listCovers.items);
    } catch (error) {
      console.error("Error fetching covers:", error);
    }
  };

  useEffect(() => {
    getArticles();
    getCoverImage();
  }, []);

  // Filter articles based on the search input
  const filteredArticles = articles.filter(article => 
    article.Titles.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <main className='flex flex-col items-center'>
      <Header pageName={pageName} search={search} setSearch={setSearch} />
      {/* Carousel */}
      <Carousel
          autoPlay
          infiniteLoop
          centerMode
          interval={3000}
          showStatus={false}
          showThumbs={false}
          showArrows={true}
          className='carousel mt-[-25px]'
        >
        {covers.map((image) => (
          <div key={image.id} className='carousel-item'>
            <img src={image.image} className='w-full h-auto bg-slate-950'/>
          </div>
        ))}
      </Carousel>

      <h2 className='p-2 text-3xl font-bold mt-8 w-full'>Recent</h2>
      <div className='p-2 w-full max-w-2xl mt-1'>
        {filteredArticles.map((article, index) => (
            <Card key={index} article={article} />
          ))}
      </div>

      {/* Footer */}
      <div className='w-full'>
        <Footers />
      </div>
    </main>
  );
}

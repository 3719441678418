import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { generateClient } from 'aws-amplify/api';
import Header from '../Components/Header';
import Footers from '../Components/Footers';

export default function ArticlePage() {
  const location = useLocation();
  const articleID = location.state?.articleID; // Ensure articleID is passed from the previous page
  const [article, setArticle] = useState(null); // State to hold the fetched article
  const [loading, setLoading] = useState(true); // State to handle loading state
  const client = generateClient();

  useEffect(() => {
    const fetchArticle = async () => {
      try {

        const result = await client.graphql({
            query: getArticles,
            variables: {
                id: articleID,
            }
          });
        setArticle(result.data.getArticles);
        setLoading(false); // Set loading to false once the article is fetched
      } catch (error) {
        console.error('Error fetching article:', error);
        setLoading(false); // Set loading to false even if there's an error
      }
    };

    if (articleID) {
      fetchArticle();
    }
  }, [articleID]);

  if (loading) {
    return <p>Loading...</p>; // Loading state while fetching the article
  }

  if (!article) {
    return <p>Article not found.</p>; // Message if no article is found
  }

  return (
    <main className="pl-2 pr-2">
        <Header/>
        <img src={article.images} alt={article.Titles} className="w-full h-72 mt-2 rounded-xl" />
        <h1 className="text-4xl font-bold mt-4">{article.Titles}</h1>
        <div className="mt-4 ">
            {article.Paragraphs && article.Paragraphs.items.map((paragraph, index) => (
            <div key={index} className="mb-4">
                <p className="text-lg">{paragraph.text}</p>
            </div>
            ))}
        </div>

        <Footers/>
    </main>
  );
}


export const getArticles = /* GraphQL */ `
  query GetArticles($id: ID!) {
    getArticles(id: $id) {
      id
      Titles
      images
      userID
      rubrique
      Paragraphs {
           items {
            id
            order
            text
            title
          }
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;